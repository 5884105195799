import React from 'react'
import { InputInterface } from './interface'
import './Input.scss'
const RadioInput = ({ id, name, defaultValue, onChange, className, label, htmlFor, value, checked, required, ref,labelClassName }: InputInterface) => {
    return (
        <div className="form-group mt-2">
            <input
                id={id}
                className={`shadow-none cursor-pointer ${className}`}
                type={'radio'}
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                value={value}
                checked={checked}
                required={required}
                ref={ref}
            />
            <label className={`inputs-label mb-1 fs-16 fw-600 ms-2 secondary-color ${labelClassName}`}htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioInput