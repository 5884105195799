import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import { HeaderHeading } from '../../../../Context'
import { getDropdown } from '../../../../constants/utils'
import { useToasts } from 'react-toast-notifications';

const { MovementInprocess } = organisms
const Index = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [processListing, setProcessListing] = useState<any>()
  const [search, setSearch] = useState('');
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [storeList, setStoreList] = useState();
  const [barcodeList, setBarcodeList] = useState();
  const { addToast } = useToasts();
  const getProcessListing = async () => {
    try {
      const response = await service.movement.inProcessListing({ search: search, page: pageCount, limit: limit, });
      if (response.status === 200) {
        setProcessListing(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const storeListing = async (id: string) => {
    try {
      let storeResponse = await service.movement.inProcessStoreById(id)
      let barcodeResponse = await service.movement.inProcessBarcodeById(id)
      if (storeResponse.status === 200 || barcodeResponse.status === 200)
        setStoreList(getDropdown(storeResponse?.data?.data, 'store_name'))
      setBarcodeList(getDropdown(barcodeResponse?.data?.data, 'bar_code','',"store_ownership"))
    } catch (error) {
      console.log(error)
    }
  }

const handleCancelled =async(id:string,cancelReason:string)=>{
  const payload={
    cancel_reason:cancelReason
  }
  try{
    const response = await service?.movement?.inProcessMovementCancelledProduct(id,payload)
    if(response?.status === 200){
      getProcessListing()
    }

  }catch(error:any){
    console.log(error)
    addToast(error?.response?.data?.message, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 3000,
  });
  }
}

  useEffect(() => {
    setHeader("Movement - In-Process")
    setSidebar(false)
    getProcessListing()
  }, [pageCount])

  useEffect(() => {
    setPageCount(1)
    if (pageCount == 1 && initialLoading) {
      getProcessListing()
    }
    setInitialLoading(true)
  }, [limit, search])

  return (
    <Wrapper>
      <MovementInprocess
        limit={limit}
        currentPage={pageCount}
        processListing={processListing}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setPageCount}
        storeListing={(id: string) => storeListing(id)}
        handleCancelled={(id:string,reason:string)=>handleCancelled(id,reason)}
        storeList={storeList}
        barcodeList={barcodeList} />
    </Wrapper>
  )
}

export default Index