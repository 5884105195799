import React, { useContext, useEffect, useState } from 'react'
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import { HeaderHeading } from '../../../../Context';
import organisms from '../../../../components/organisms';
import "./style.scss"
import { useToasts } from 'react-toast-notifications';
const { LocationGroup } = organisms

const Index = () => {
    const [groupList, setGroupList] = useState()
    const [remainingList, setRemainingList] = useState()
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const getGroupList = async () => {
        try {
            let response = await service.locationGrouping.groupList()
            let remaining = await service.locationGrouping.remainingPincodesList()
            if (response.status === 200) {
                setGroupList(response.data.data)
                setRemainingList(remaining.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const _handleDelete = async (id: string) => {
        try {
            let response = await service.locationGrouping.removeGroup(id)
            if (response.status === 200) {
                getGroupList()
            }
            addToast(response?.data?.message, {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        } catch (error:any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            console.log(error)
        }
    }
    useEffect(() => {
        setHeader("Setting - Location Groups")
        setSidebar(false)
        getGroupList()
    }, [])

    return (
        <Wrapper>
            <LocationGroup
                groupList={groupList}
                remainingList={remainingList}
                getGroupList={getGroupList}
                _handleDelete={_handleDelete}
            />
        </Wrapper>
    )
}

export default Index