import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { report } = Dictionary

const reportService = {
    customer: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.customerReport(), { params: paramsObj })
    },
    gstInput: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.gstInputReport(), { params: paramsObj })
    },
    storeSalesInvoice: async (payload: any) => {
        return axios.post(BASE_URL + report.storeSalesInvoiceReport(), payload)
    },
    storeSalesReturn: async (payload: any) => {
        return axios.post(BASE_URL + report.storeSalesReturnReport(), payload)
    },
    storeSalesMonth: async (payload: any) => {
        return axios.post(BASE_URL + report.storeSalesMonthReport(), payload)
    },
    storeSalesBrand: async (payload: any) => {
        return axios.post(BASE_URL + report.storeSalesBrandReport(), payload)
    },

    ownershipTransferReport: async (payload: any) => {
        return axios.post(BASE_URL + report.ownershipTransferReport(), payload)
    },

    inventoryMovement: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryMovementReport(), { params: paramsObj })
    },
    inventoryBarcode: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryBarcodeReport(), { params: paramsObj })
    },
    inventoryProduct: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryProductReport(), { params: paramsObj })
    },
    gstOutput: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.gstOutputReport(), { params: paramsObj })
    },
    store: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.storeReport(), { params: paramsObj })
    },
    barcodeAuditTrail: async (payload: any) => {
        return axios.post(BASE_URL + report.barcodeAuditReport(), payload)
    },

    inventoryAdditionalInvoiceWise: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryAdditionalInvoiceWiseReport(), { params: paramsObj })
    },
    inventoryAdditionalBarcodeWise: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryAdditionalBarcodeWiseReport(), { params: paramsObj })
    },
    invoicing: async (payload: any, paramsObj: {}) => {
        return axios.post(BASE_URL + report.invoicingReport(), payload, { params: paramsObj })
    },
    invoicingReturn: async (payload: any) => {
        return axios.post(BASE_URL + report.invoicingReturn(), payload)
    },
    barcodeSearch: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.barcodeSearch(), { params: paramsObj })
    },

    settlementNote: async (payload: any) => {
        return axios.post(BASE_URL + report.settlementNote(), payload)
    },
    settlementHistory: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.settlementNote(), { params: paramsObj })
    },
    settlementNoteShare: async (id:string, payload: {}) => {
        return axios.get(BASE_URL + report.settlementNoteShare(id), payload)
    },

    // For Account
    trailBalanceReport: async (payload: {}) => {
        return axios.post(BASE_URL + report.trailBalanceReport(), payload)
    },
    glAccountReport: async (payload: any) => {
        return axios.post(BASE_URL + report.glAccountReport(), payload)
    },
    TransactionReport: async (payload: any) => {
        return axios.post(BASE_URL + report.transactionReport(), payload)
    },
    StoreBalanceReport: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.storeBalanceReport(), { params: paramsObj })
    },


    getGeneralAccountList: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.generalAccountList(), { params: paramsObj })
    }


}
export default reportService
