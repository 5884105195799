import { useEffect, useRef } from 'react'
import specXLogo from '../../../assets/Images/barcode_logo.jpg'
import Image from '../../atoms/Image'
import { numberWithCommas } from '../../../constants/utils'
import { useReactToPrint } from 'react-to-print';
import './print.css'
const Index = ({ downloadBarcode, togglePrice }: any) => {
    const targetRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => targetRef.current,
        documentTitle: "barcodes" + Math.floor(100000 + Math.random() * 900000).toString()
    });

    useEffect(() => {
        if (downloadBarcode && downloadBarcode.length > 0) {
            // generatePDF(targetRef, options)
            handlePrint()
        }
    }, [downloadBarcode])

    return (
        <>
            <div className="container barcode-container ps-2 " ref={targetRef}>
                {downloadBarcode?.map((item: any, index: number) => {
                    return (
                        <div className={`d-flex flex-wrap align-items-center justify-content-around ${((index + 1) % 14 == 0) && 'page-break'
                            } `} style={{
                                width: '50mm',
                                // minHeight: '13mm',
                                minHeight: '15mm',
                                marginTop: '1.75mm',
                                marginBottom: '1.55mm',
                                marginLeft: '1.5mm',
                            }}>
                            <div className="" style={{
                                width: '19mm',

                            }}>
                                <h1 className="title text-uppercase m-0 p-0 fw-700">{item.brand_name}</h1>
                                <p className={`price m-0 p-0 fw-800 ${togglePrice ? "show-barcode-price" : "hide-barcode-price"}`}>₹ {numberWithCommas(item.product_srp)} /-</p>
                                <p className='price m-0 p-0 fw-800'>{item.bar_code}</p>
                                <p className='product_id m-0 p-0 fw-600'>{item.product_id_display}</p>
                            </div>

                            <div className="" style={{
                                width: '27mm',
                                marginLeft: '15px',

                            }}>
                                <Image className='img-fluid' src={specXLogo} style={{ width: '150px' }} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Index