import React, { useState, useEffect } from 'react';
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import organisms from "../../../components/organisms";
import service from '../../../api/services';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import constant from '../../../constants/constant';
import { useToasts } from 'react-toast-notifications';
import { Product } from './interface';
import { getDropdownValue, convertArrayToKeyValue } from '../../../constants/utils';
import axios from 'axios';

const { AddProduct } = organisms
const AddProducts = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [productType, setProductType] = useState([])
    const [deleteClick, setDeleteClick] = useState(false)
    const [brand, setBrand] = useState([])
    const [brandResponse, setBrandResponse] = useState([])
    const [productShape, setProductShape] = useState([])
    const [previewImageUrl, setPreviewImageUrl] = useState([])
    const [productMaterial, setProductMaterial] = useState([])
    const [productColor, setProductColor] = useState([])
    const [glassSizes, setGlassSizes] = useState<any>([])
    const [templeSizes, setTempleSizes] = useState<any>([])
    const [bridgeSizes, setBridgeSizes] = useState<any>([])
    let params = useParams();
    let productEditID = params.id || ''
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isDuplicateValue = searchParams.get('isDuplicate');
    const urlParams = new URLSearchParams(location.search);
    const typeValue:any = urlParams?.get('add-type')
    const [productData, setProductData] = useState<any>({
        isActive: false,
        product_id_display: '',
        modal_number: "",
        brand_color_code: '',
        brand_color_name: '',
        specx_colors: [],
        lens_colors: [],
        glass_size: '',
        temple_size: '',
        bridge_size: '',
        product_with_case: false,
        description: '',
        product_srp: '',
        features: [],
        rim_type: '',
        gender: [],
        warranty: '',
        weight: '',
        product_type_id: '',
        brand_id: '',
        product_materials: [],
        shapes: [],
        images: [],
        cover_index: 0,
        isPolarised: '',
        productDescription: ""
    })
    const getDropdown = (response: []) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id || value.product_type.id,
                "label": value.name || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials,
                'brand-type': value.brand_type || 1
            }
            )
        })
        return dropdown
    }

    const getProductData = async () => {
        try {
            // let productTypeResponse = await service.productData.productTypeListingService({})
            let productShapeResponse = await service.productData.productShapeListingService({})
            let productMaterialResponse = await service.productData.productMaterialListingService({})
            let productColorResponse = await service.productData.productColorListingService({})
            let brandResponse = await service.productData.brandListingService({})
            let sidesResponse = await service.productData.sizeDetails()

            setGlassSizes(convertArrayToKeyValue(sidesResponse?.data.data.glass_size))
            setTempleSizes(convertArrayToKeyValue(sidesResponse?.data.data.temple_size))
            setBridgeSizes(convertArrayToKeyValue(sidesResponse?.data.data.bridge_size))
            setBrandResponse(brandResponse?.data?.data)
            setBrand(getDropdown(brandResponse?.data?.data))
            setProductShape(getDropdown(productShapeResponse?.data?.data))
            setProductMaterial(getDropdown(productMaterialResponse?.data?.data))
            setProductColor(getDropdown(productColorResponse?.data?.data))
            if (productEditID?.length > 0) {

                getEditData(getDropdown(brandResponse.data.data),
                    brandResponse.data.data,
                    getDropdown(productShapeResponse?.data?.data))
            }
        } catch (error) {
            console.error(error)
        }
    }


    const addProducts = async () => {

        const formdata: any = new FormData();
        let singleKey: string[] = ['rim_type', 'warranty', 'weight', 'product_type_id',
            'brand_id', 'isPolarised', 'glass_size', 'temple_size', 'bridge_size']
        let multiKey: string[] = ['product_materials', 'features', 'specx_colors', 'lens_colors',
            'shapes', 'gender',]
        for (const key in productData) {
            if (productData.hasOwnProperty(key) && productData[key] !== undefined &&
                productData[key] !== '' && productData[key] !== null) {
                if (singleKey.includes(key)) {
                    formdata.append(key, productData[key].value)
                } else if (multiKey.includes(key)) {
                    productData[key].forEach((item: any) => formdata.append(key, item.value));
                } else if (key === 'images') {
                    productData?.images.forEach((image: any) => formdata.append('product_images', image))
                } else {
                    formdata.append(key, productData[key]);
                }
            }
        }
        try {
            if (isDuplicateValue) {
                let response = await service.product.add(formdata, { "Content-type": "multipart/form-data" })
                if (response.status === 201) {
                    addToast('Product Duplicate successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    // navigate(`/products/pending?pending-type=${typeValue}`)
                    navigate(-1)
                }
            } else {
                if (productEditID) {
                    let response = await service.product.edit(productEditID, formdata, { "Content-type": "multipart/form-data" })
                    if (response.status === 200) {
                        addToast('Product updated successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                        navigate(-1)
                    }
                } else {
                    let response = await service.product.add(formdata, { "Content-type": "multipart/form-data" })
                    if (response.status === 201) {
                        addToast('Product Add successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                        navigate(`/products/pending?pending-type=${typeValue}`)
                        // navigate(-1)
                    }
                }
            }
        } catch (error: any) {
            console.log(error);
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const getEditData = async (brand: any, brandResponseData: any, shape: any) => {
        let productData
        try {
            let response = await service.product.getDetailById(productEditID)
            if (response?.data?.data?.brand?.id && brandResponse) {
                const filteredData: any = brandResponseData.filter((item: any) => item?.id === response?.data?.data?.brand?.id);
                productData = getDropdown(filteredData[0].product_types)
                setProductType(getDropdown(filteredData[0].product_types))
            }

            handleEditData(response, brand, productData, shape)

        } catch (error) {
            console.log("error: ", error)
        }
    }

    useEffect(() => {
        getProductData()
    }, [])
    useEffect(() => {
        if (deleteClick) {
            deleteProduct()
        }
    }, [deleteClick])

    const getDropdownData = (response: any, constant_key: any) => {
        let tempArr: any = []
        response && response?.map((data: any) => {
            constant_key.map((item: any) => {
                if (data === item.value) {
                    tempArr?.push(item)
                }
            })
        })
        return tempArr
    }

    const handleEditData = (response: any, brandData: any, type: any, shape: any) => {
        setPreviewImageUrl(response.data.data.images)
        let editProductData: Product = {
            isActive: response?.data?.data?.isActive,
            product_id_display: response?.data?.data?.product_id_display,
            modal_number: response?.data?.data?.modal_number,
            brand_color_code: response?.data?.data?.brand_color_code,
            brand_color_name: response?.data?.data?.brand_color_name,
            glass_size: {
                "value": response?.data?.data?.glass_size,
                "label": response?.data?.data?.glass_size,
            },
            temple_size: {
                "value": response?.data?.data?.temple_size || '',
                "label": response?.data?.data?.temple_size || '',
            },
            bridge_size: {
                "value": response?.data?.data?.bridge_size || '',
                "label": response?.data?.data?.bridge_size || '',
            },
            product_srp: response?.data?.data?.product_srp,
            product_with_case: response?.data?.data?.product_with_case,
            productDescription: response?.data?.data?.description,
            cover_index: response?.data?.data?.cover_index,

            warranty: {
                "value": getDropdownValue(constant.PRODUCT_WARRANTY, response?.data?.data?.warranty).value,
                "label": getDropdownValue(constant.PRODUCT_WARRANTY, response?.data?.data?.warranty).label,
            },
            isPolarised: {
                "value": getDropdownValue(constant.POLARIZED, response?.data?.data?.isPolarised).value,
                "label": getDropdownValue(constant.POLARIZED, response?.data?.data?.isPolarised).label,
            },
            weight: {
                "value": getDropdownValue(constant.PRODUCT_WEIGHT, response?.data?.data?.weight).value,
                "label": getDropdownValue(constant.PRODUCT_WEIGHT, response?.data?.data?.weight).label,
            },
            rim_type: {
                "value": getDropdownValue(constant.PRODUCT_RIM_TYPE, response?.data?.data?.rim_type).value,
                "label": getDropdownValue(constant.PRODUCT_RIM_TYPE, response?.data?.data?.rim_type).label,
            },
            product_type_id: {
                "value": getDropdownValue(type, response?.data?.data?.product_type_id).value,
                "label": getDropdownValue(type, response?.data?.data?.product_type_id).label,
                "code": getDropdownValue(type, response?.data?.data?.product_type_id).code,
            },
            // TODO: If This page cash remove the "code" from here as well as from utils function ;)
            brand_id: {
                "value": getDropdownValue(brandData, response?.data?.data?.brand_id).value,
                "label": getDropdownValue(brandData, response?.data?.data?.brand_id).label,
                "code": getDropdownValue(brandData, response?.data?.data?.brand_id).code,
            },
            gender: getDropdownData(response?.data?.data?.gender, constant.GENDER),
            shapes: getDropdown(response?.data?.data?.shapes),
            specx_colors: getDropdown(response?.data?.data?.specx_colors),
            lens_colors: getDropdown(response?.data?.data?.lens_colors),
            features: getDropdownData(response?.data?.data?.features, constant.PRODUCT_FEATURES),
            product_materials: getDropdown(response?.data?.data?.product_materials),
            images: response?.data?.data?.images
        }
        setProductData(editProductData)
    }

    const deleteProduct = async () => {
        try {
            let response = await service.product.delete(productEditID)
            if (response.status === 200) {
                addToast('Product Delete successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <AddProduct
                // setButtonClick={setButtonClick}
                productData={productData}
                setProductData={setProductData}
                productType={productType}
                brand={brand}
                productShape={productShape}
                productMaterial={productMaterial}
                productColor={productColor}
                glassSizes={glassSizes}
                templeSizes={templeSizes}
                bridgeSizes={bridgeSizes}
                imageUrl={previewImageUrl}
                setDeleteClick={setDeleteClick}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                isDuplicateValue={isDuplicateValue}
                addProducts={() => { addProducts() }}
            />
        </Wrapper>
    )
}
export default AddProducts