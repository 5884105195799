import Button from './Button';
import Image from './Image';
import Search from './Search';
import Select from './Select';
import Table from './Table';
import Input from './Input';
import RadioInput from './RadioInput';
import TextArea from './TextArea';
import CustomTable from './CustomTable';
import Pagination from './Pagination';
import Badge from './Bages';
import CustomSearch from './CustomSearch';
import CustomImages from './CustomImage'
import LocationBadge from './LocationBadge'
import CommonModal from './CommonModal'
const atoms = {
    Button,
    Image,
    Search,
    Select,
    Table,
    Input,
    RadioInput,
    TextArea,
    CustomTable,
    Pagination,
    Badge,
    CustomSearch,
    CustomImages,
    LocationBadge,
    CommonModal,
}
export default atoms