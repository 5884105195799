import React, { useState, useEffect, useContext } from 'react';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues';
import constant from '../../../../constants/constant';
import { InvoiceData } from '../Addition/interface'
import { HeaderHeading } from '../../../../Context'
import { useSearchParams } from 'react-router-dom';
import { getParams } from '../../../../constants/utils';
const { ApproveInventory } = organisms
const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('approve-list-type'))
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [invoiceDetail, setInvoiceDetail] = useState<InvoiceData | any>({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    
    
    useEffect(() => {
        setTypeValue(getParams('approve-list-type'))
    }, [searchParams])
    
    const getInvoiceData = async () => {
        try {
            let response = await service.inventory.view({ brand_category: typeValue, "page": currentPage, "search": search, "limit": limitPerPage, "status": constant.VERIFICATION_STATUS.VERIFIED })
            if (response.status === 200) {
                setInvoiceDetail(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateStatus = async (id: any, status: any) => {
        try {
            let response = await service.inventory.updateStatusById(id, { "status": status });
            getInvoiceData()
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        setHeader("Inventory - Approved")
        setSidebar(false)
        getInvoiceData()
    }, [currentPage, searchParams])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getInvoiceData()
        }
        setInitialLoading(true)
    }, [limitPerPage, search,typeValue])

   
    return (
        <Wrapper>
            <ApproveInventory
                invoiceDetail={invoiceDetail}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                updateVerificationStatus={(id: string, status: any) => handleUpdateStatus(id, status)}
                setSearch={setSearch}
                currentPage={currentPage}
                limit={limitPerPage}
            />
        </Wrapper>
    )
}
export default Index