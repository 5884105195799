import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { inventory } = Dictionary

const invoiceDetail = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + inventory.inventory(), { params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + inventory.inventory(), data)
  },
  delete: async (id: string) => {
    return axios.delete(BASE_URL + inventory.invoiceById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + inventory.invoiceById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + inventory.invoiceById(id))
  },
  getInvoiceDataCount: async () => {
    return axios.get(BASE_URL + inventory.invoiceDataCount())
  },
  updateStatusById: async (id: string, data: any) => {
    return axios.put(BASE_URL + inventory.verificationStatus(id), data)
  },
  supplierAndStoreData: async () => {
    return axios.get(BASE_URL + inventory.supplierAndStore())
  },
  brandListing: async (paramsObj?: any) => {
    return axios.get(BASE_URL + inventory.brandListing(), { params: paramsObj })
  },
  quantitySearch: async (data: {}) => {
    return axios.post(BASE_URL + inventory.quantitySearch(), data)
  },

  updateQuantity: async (data: {}, id: string) => {
    return axios.put(BASE_URL + inventory.updateQuantity(id), data)
  },

  searchView: async (data: {}) => {
    return axios.post(BASE_URL + inventory.inventoryItems(), data)
  },
  brandView: async (paramsObj?:any) => {
    return axios.get(BASE_URL + inventory.inventoryBrand(),{ params: paramsObj })
  },
  storeView: async (paramsObj?:any) => {
    return axios.get(BASE_URL + inventory.inventoryStore(),{ params: paramsObj })
  },

  deletePendingInventory: (id: string) => {
    return axios.delete(BASE_URL + inventory.deletePendingInventory(id))
  },
}
export default invoiceDetail
