import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import atoms from '../../../atoms'
import moment from 'moment'
import { listAllocationStore, StoreData } from '../BrandAllocation/interface'
import { Tooltip } from 'react-tooltip'

const { Search, Button, Table, Pagination } = atoms
const Index = ({ setLimitPerPage, setCurrentPage, setSearch, storeAllocationList, currentPage, limit }: listAllocationStore) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Store ID', selector: (row: StoreData) => row.store_id_display, sortable: true,  },
        { name: 'Date', selector: (row: any) =>row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Store Name', selector: (row: StoreData) => row.store_location ? row?.store_location : row?.store_name, },
        { name: 'Store City', selector: (row: StoreData) => row.city,},
        { name: 'Brand Allocated', selector: (row: StoreData) => row?.brand_allocated,minWidth:'25%' },
        { name: 'Action', selector: (row: any) => row.action, },
    ]
    const _handleAllocatedBrand = (brands: any) => {
        let tempBrandName: any[] = []; // Initialize as an empty array
        brands?.forEach((brandItem: any) => {
            tempBrandName.push(brandItem?.brand_name); // Push brand_name values into the array
        });
        return tempBrandName?.join(',')
    }
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 text-end">
                    <Button name={"Add Brands Allocation"}
                        className=''
                        onClick={() => navigate('/store/brand/allocation/add')}
                    />
                </div>
            </div>
            <div>
                <Table columns={columns}
                    data={storeAllocationList?.data?.map((item: StoreData, index: number) => {
                        return {
                            ...item,
                            brand_allocated: <p className='p-0 fs-12 fw-400 ellipsis' 
                                key={`brand${index}`}
                                data-tooltip-id={`brand${index}`}
                                data-tooltip-content={_handleAllocatedBrand(item?.brands)}

                            >{_handleAllocatedBrand(item?.brands)}
                                <Tooltip id={`brand${index}`} style={{maxWidth : '500px'}}/></p>,
                            action: (
                                <Link to={`/store/brand/allocation/edit/${item.id}`}>
                                    <Button className="table-edit-button fs-14 fw-400">
                                        <div className='d-flex align-items-center'>
                                            <FiEdit />
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </div>
                                    </Button>
                                </Link>
                            )
                        };
                    })}
                    rowHeights="10px"
                />
            </div >
            <div>
                <Pagination
                    totalCount={storeAllocationList?.count}
                    currentPage={currentPage}
                    totalPages={storeAllocationList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index