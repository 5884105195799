import React from "react";
import { IndexProps } from "./interface";
import './priceToggle.scss'

const Index = ({ togglePrice, handleRadioChange }: IndexProps) => {
  return (
    <div className="form-check form-switch d-flex align-item-center px-0">
      <label
        className="fs-16 fw-600 secondary-color me-5 mt-1"
        htmlFor="flexSwitchCheckChecked"
      >
        Show Price on PDF:
      </label>
      <input
        className="form-check-input no-outline custom-width"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        checked={togglePrice}
        onChange={() => handleRadioChange(!togglePrice)}
      />
    </div>
  );
};

export default Index;
