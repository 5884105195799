import React, { useContext, useEffect, useState } from 'react'
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import { HeaderHeading } from '../../../../Context';
import organisms from '../../../../components/organisms';
import "./style.scss"
const { LocationMatrix } = organisms

const Index = () => {
    const [mappingList, setMappingList] = useState()
    const [transitData, setTransitData] = useState<any>([]);

    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const getMappingList = async () => {
        try {
            let response = await service.locationGrouping.groupList()
            if (response.status === 200) {
                setMappingList(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getCombinationDays = async () => {
        try {
            const response = await service.locationGrouping.groupMappingList();
            if (response.status === 200) {
                setTransitData(response.data.data);
            }
        } catch (error) {
            console.log("Error fetching transit days:", error);
        }
    };
    useEffect(() => {
        getCombinationDays();
        setHeader("Setting - Location Matrix")
        setSidebar(false)
        getMappingList()
    }, [])

    return (
        <Wrapper>
            <h1 className='fs-4'>Enter transit days</h1>
            <LocationMatrix
                mappingList={mappingList}
                transitData={transitData}
                getMappingList={() => getMappingList()}
                getCombinationDays={() => getCombinationDays()}
            />
        </Wrapper>
    )
}

export default Index