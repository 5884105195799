import { useState } from 'react'
import atoms from '../../../atoms'
import { FaEdit, FaTrash } from 'react-icons/fa';
import AddNew from "./addNew"
import service from '../../../../api/services';
import { useToasts } from 'react-toast-notifications';
import molecules from '../../../molecules';
const { Button, LocationBadge, CommonModal } = atoms
const { ConfirmationModal } = molecules

const Index = ({ groupList, remainingList, getGroupList, _handleDelete }: any) => {
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [seeMoreModal, setSeeMoreModal] = useState<boolean>(false)
    const { addToast } = useToasts();
    const [editData, setEditData] = useState({})
    const [isEditable, setIsEditable] = useState({ id: '', boolean: false })
    const [currSelected, setCurrSelected] = useState<string>('')
    const _handleSubmit = async (payload: {}) => {
        try {
            let response
            if (isEditable?.id && isEdit) {
                response = await service.locationGrouping.updateGroup(isEditable?.id, payload)

            } else {
                response = await service.locationGrouping.createGroup(payload)

            }
            if (response.status === 200 || response.status === 201) {
                getGroupList()
                setModal(false)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleEdit = async (id: string) => {
        setIsEdit(true)
        setIsEditable({ id: id, boolean: true })
        setModal(true)
        try {
            let response = await service.locationGrouping.getGroupById(id)
            if (response.status === 200) {
                setEditData(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const _handleDeleteConfirm = async () => {
        _handleDelete(currSelected)
        setDeletePopup(!deletePopup)
    }
    return (
        <div className="container-grouping ">
            <div className='row mb-3'>
                <div className="col-10 d-flex align-items-center " style={{ fontWeight: 700, fontSize: '22px' }}>
                    Remaining:
                </div>
                <div className="col-2 text-end">
                    <Button name={"Add New"}
                        onClick={() => { setIsEdit(false), setModal(true) }}
                    />
                </div>
            </div>
            <div className='top-item-container'>
                <div className="top-items d-flex flex-wrap ">
                    {remainingList && remainingList.map((item: string, idx: number) => (
                        <>
                            {(idx + 1) <= 11 ? (
                                <LocationBadge label={item} />
                            ) : idx === remainingList?.length - 1 ? (
                                <Button
                                    name={"Veiw More"}
                                    onClick={() => { setSeeMoreModal(true); }}
                                />
                            ) : ''}
                        </>
                    ))
                    }
                    {remainingList?.length === 0 && <h5 >No Pincodes Remaining</h5>}

                </div>
            </div>
            <div >
                <div className=" py-4 row">
                    {groupList && groupList?.map((items: any) => (
                        <div className="group-container col-xxl-4 col-xl-4  col-lg-4 col-md-6" key={items.id}>
                            <div className="group">
                                <div className='row'
                                >
                                    <div className="col-8 d-flex justify-content-between align-items-center">
                                        <h6 className="group-title">{items?.group_name}</h6>
                                    </div>
                                    <div className='col-4 d-flex gap-2 justify-content-end'>
                                        <button className=" action-btn green-border button-bg-green" onClick={() => _handleEdit(items.id)}>
                                            <FaEdit className='icon-color-green' />
                                        </button>
                                        <button className=" action-btn red-border  button-bg-red" onClick={() => {
                                            setCurrSelected(items.id)
                                            setDeletePopup(!deletePopup)
                                        }}>
                                            <FaTrash className='icon-color-red' />
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div className='group-scroll'>
                                    <div className="group-items">
                                        {items?.pincodes?.map((item: string, idx: number) => (
                                            <LocationBadge key={idx} label={item} />
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                    }
                </div>
            </div>
            {modal &&
                <AddNew
                    remainingList={remainingList}
                    show={modal}
                    onHide={() => setModal(false)}
                    setPayload={_handleSubmit}
                    editData={editData}
                    isEdit={isEdit}
                />
            }
            {seeMoreModal && <div>
                <CommonModal isOpen={seeMoreModal} toggle={() => setSeeMoreModal(false)} title={'Remaining Pincode'} >
                    <div className="top-items d-flex flex-wrap " style={{ overflow: 'auto', maxHeight: '75vh', minHeight: '30vh' }}>
                        {remainingList && remainingList.map((item: string, idx: number) => (
                            <LocationBadge label={item} />
                        ))}

                    </div>
                </CommonModal>

            </div>}
            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => _handleDeleteConfirm()}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'delete this group'} />}
        </div>

    )
}

export default Index