import React, { ReactNode } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RxCross2 } from 'react-icons/rx';
import './style.scss';
import Button from '../Button';  // Direct import for debugging

interface CommonModalProps {
  isOpen?: boolean;
  toggle?: () => void;
  title?: string;
  children?: ReactNode;
}

const CommonModal: React.FC<CommonModalProps> = ({ isOpen, toggle, title, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="d-flex border-0 position-relative"  >
        {title}
        <div className="position-absolute py-1 px-2"
          style={{
            top: '14%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222',
            borderRadius: '50%', cursor: 'pointer'
          }}
          onClick={toggle}>
          <RxCross2 style={{ color: '#222' }} />
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CommonModal;
