import React from 'react';
import Wrapper from '../../../common/Wrapper/Wrapper';
import molecules from '../../../molecules';
import atoms from '../../../atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandAllocation, BrandData } from '../BrandAllocation/interface';
import constant from '../../../../constants/constant';

const { BrandTable } = molecules
const { Search, Button } = atoms
const EditBrandAllocation = ({ brandEditData, setBrandEditData, search, setSearch, setButtonClick, selectedBrand, setSelectedBrand, removeBrand }: BrandAllocation) => {
    const navigate = useNavigate()
    const params = useParams()
    const editStoreId = params?.id || ''
    const handleGetData = (data: BrandData) => {
        setBrandEditData({ ...brandEditData, brands: data });
    }
    return (
        <div>
            <div className='row'>
                <div className='col-3'>
                    <div className='fw-600'>Store ID</div>
                    <div className='mt-2'>{brandEditData?.store_id}</div>
                </div>
                <div className='col-3'>
                    <div className='fw-600'>Store Name</div>
                    <div className='mt-2'>{brandEditData?.store_name}</div>
                </div>
                <div className='col-3'>
                    <div className='fw-600'>Balance in Deposit Account</div>
                    <div className='mt-2'>{brandEditData?.deposit_amount}</div>
                </div>
                <div className='col-3'>
                    <div className='fw-600'>Total No. of Brands Allocated</div>
                    <div className='mt-2'>{brandEditData?.brands?.length} &nbsp;Brands</div>
                </div>
            </div>
            <hr />
            <div className="row">

                <div className="col-6">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 text-end">
                    <Button name={"Add Brand"}
                        className=''
                        onClick={() => navigate(`/store/brand/add/${editStoreId}?add-type=${constant.BRAND_CATEGORY[0].value}`)}
                    />
                </div>
            </div>
            <BrandTable brandList={brandEditData.brands} setBrandList={(filterData: BrandData) => handleGetData(filterData)} search={search}
                setSearch={setSearch} setButtonClick={setButtonClick} selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                removeBrand={(brandId: string) => removeBrand(brandId)}
            />
        </div>
    )
}
export default EditBrandAllocation