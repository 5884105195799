import { useContext, useEffect, useState } from "react";
import service from "../../../../api/services";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from '../../../../Context'
import { getDropdown, getParams } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import { useSearchParams } from "react-router-dom";
const { PendingBarcodeStatus } = organisms;
const pendingIndex = () => {
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('status-pending-type') == constant.BRAND_CATEGORY[0]?.value)
  const [pendingStatusList, setPendingStatus] = useState<string>();
  const [search, setSearch] = useState("");
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(Default_Page_Number);
  // const [pageCount, setPageCount] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [allBarcode, setAllBarcode] = useState<any>([]);
  const [storeData, setStoreData] = useState([]);
  const [storeId, setStoreId] = useState<any>([])

  useEffect(() => {
    let tempValue: any = getParams('status-pending-type')
    setTypeValue(tempValue == constant.BRAND_CATEGORY[0]?.value)
  }, [searchParams])

  const getPendingStatus = async () => {
    const storeIds = storeId?.map((item: any) => item.value)
    const data = {
      search: search,
      page: currentPage,
      limit: limit,
      barcode_status: constant?.BARCODE_STATUS?.PENDING,
      store_ids: storeIds,
      is_recognised: typeValue
    }
    try {
      const response = await service.barcodeStatus.barcodePendingService(data);
      setPendingStatus(response.data);
      const combinedArray = [...allBarcode, ...response.data.data];
      const uniqueIds = new Set<number>();

      // Filter the combined array to include only unique entries based on ID
      const uniqueArray = combinedArray.filter((item) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          return true;
        }
        return false;
      });
      // Update the state with the unique array
      setAllBarcode(uniqueArray);
    } catch (error) {
      console.log(error);
    }
  };

  const getStoreList = async () => {
    try {
      let storeResponse = await service.inventory.storeView()
      if (storeResponse.status === 200) {
        setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setHeader("Inventory - Pending Barcode")
    setSidebar(false)
    getPendingStatus();
  }, [currentPage, typeValue]);

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getPendingStatus()
    }
    setInitialLoading(true)
  }, [limit, search, storeId])

  useEffect(() => {
    getStoreList()
  }, [])

  return (
    <Wrapper>
      <PendingBarcodeStatus
        pendingStatusList={pendingStatusList}
        setSearch={setSearch}
        setLimitPerPage={setLimit}
        setCurrentPage={setCurrentPage}
        getPendingStatus={getPendingStatus}
        limit={limit}
        currentPage={currentPage}
        allBarcode={allBarcode}
        storeData={storeData}
        handleChange={(value: any) => setStoreId(value)}
      />
    </Wrapper>
  );
};
export default pendingIndex;
