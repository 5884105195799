import React, { useState } from 'react'
import atoms from "../../../atoms";
import { useNavigate } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import './index.scss'
import { checkPermissionsInArray } from '../../../../constants/utils';
import { AllPermissions } from '../../../../constants/permissions';
import { IndexProps } from './interface';
import constant from '../../../../constants/constant';
const { Search, Button, Image } = atoms
const Index = ({ setSearch, brandData, setBrandData }: IndexProps) => {
    const navigate = useNavigate()
    const [sortAsc, setSortAsc] = useState(true);
    const [sortTypeAsc, setSortTypeAsc] = useState(true);
    const urlParams = new URLSearchParams(location.search);
    const typeValue = urlParams?.get('brand-type')
    const sortDataByName = () => {
        const sortedData = [...brandData].sort((a, b) => {
            if (sortAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        });

        setBrandData(sortedData);
        setSortAsc(!sortAsc)
    };
    const sortProductTypesByName = () => {
        const sortedData = [...brandData].sort((a, b) => {
            if (sortTypeAsc) {
                return a?.brand_name?.localeCompare(b?.brand_name);
            } else {
                return b?.brand_name?.localeCompare(a?.brand_name);
            }
        })?.map((obj) => ({
            ...obj,
            product_types: [...obj.product_types]?.sort((a, b) => {
                if (sortTypeAsc) {
                    return a?.product_type?.name?.localeCompare(b?.product_type?.name);
                } else {
                    return b?.product_type?.name?.localeCompare(a?.product_type?.name);
                }
            }),
        }));

        setBrandData(sortedData);
        setSortTypeAsc(!sortTypeAsc);
    };


    return (
        <>
            <div className="row mb-3">
                <div className="col-6"><Search searchCallBack={(e: string) => { setSearch(e) }} /></div>
                {checkPermissionsInArray(AllPermissions.Brand_Add) && <div className="col-6 text-end">
                    <Button name={"Add Brand"}
                        className=''
                        onClick={() => navigate(`/product/brand/add?brand-type=${typeValue}`)}
                    />
                </div>}
            </div>
            {brandData.length > 0 ? <div className='custom_table table-responsive'>
                <table className='mb-5 w-100'>
                    <thead className='table-thead background-blue color-black sticky-top'>
                        <th>
                            Brand Name{sortAsc ? <BsArrowDownShort onClick={sortDataByName} className="cursor-pointer" /> :
                                <BsArrowUpShort className="cursor-pointer" onClick={sortDataByName} />}
                        </th>
                        <th>Brand Code</th>
                        <th>Product Type{sortTypeAsc ? <BsArrowDownShort onClick={sortProductTypesByName} className="cursor-pointer" /> :
                            <BsArrowUpShort className="cursor-pointer" onClick={sortProductTypesByName} />}</th>
                        <th>Brand Margin &#40;%&#41; </th>
                        <th>Brand Landing Cost &#40;%&#41;</th>
                        <th>Brand Discount &#40;%&#41;</th>
                        <th>Specx Landing Cost  &#40;%&#41;</th>
                        {checkPermissionsInArray(AllPermissions.Brand_Edit) &&
                            <th>Action</th>}
                    </thead>
                    <tbody className='mt-3 table-tbody' >
                        {brandData.map((brand: any, ind: number) => (
                            brand.product_types.map((product: any, index: number) => (
                                <tr key={`${brand.id}_${index}`}
                                // className={`${index % 2 !== 0 && "background-light-blue"} `} 
                                >
                                    {index === 0 ? (

                                        <td rowSpan={brand.product_types.length} className={` border-right p-1 `} style={{ backgroundColor: "#fff" }}>
                                            <div className='d-flex'>
                                                <div className='mt-1 p-1 img-cont mb-2'>
                                                    <Image src={brand.brand_logo} className='  img' />
                                                </div>
                                                <div className='mt-2 brandName p-2'>
                                                    <span className="fw-500">{brand.brand_name}</span>
                                                </div>
                                            </div>
                                        </td>

                                    ) : null}
                                    <td>{brand.brand_code}</td>
                                    <td>{product?.product_type?.name || '-'}</td>
                                    <td>{product.brand_margin || 0}</td>
                                    <td>{product.brand_landing_cost || 0}</td>
                                    <td>{product.brand_discount || 0}</td>
                                    <td>{product.specx_landing_cost || 0}</td>
                                    {(index === 0 && checkPermissionsInArray(AllPermissions.Brand_Edit)) ? (
                                        <>
                                            <td rowSpan={brand.product_types.length} className='border-left ' style={{ backgroundColor: "white" }}>
                                                <Button className="table-edit-button fs-14 fw-400" onClick={() => navigate(`/product/brand/edit/${brand.id} `)}>
                                                    <div className='d-flex align-items-center'>
                                                        <FiEdit />
                                                        <span className='ms-2 mb-0'>Edit</span>
                                                    </div>
                                                </Button>
                                            </td>
                                        </>
                                    ) : null}
                                </tr>
                            ))
                        ))}
                    </tbody>

                </table>
                {/* <div className="pagination-buttons d-flex justify-content-center mt-5 gap-3">
                    <div className='d-flex align-items-center'>
                        <Button onClick={handlePrevPage} isDisabled={currentPage === 1} className={`${currentPage !== 1 ? 'main-color' : 'secondary-color'} pagination-style mb-0 p-0 ms-2`} >
                            <div className='d-flex align-items-center '>
                                <IoIosArrowBack />
                                <p className='m-0 ps-1'>Previous</p>
                            </div>
                        </Button>

                    </div>
                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button
                            key={pageNumber}
                            className={pageNumber === currentPage ? 'active' : ''}
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <div className='d-flex align-items-center'>
                        <Button onClick={handleNextPage} isDisabled={currentPage === totalPages} className={`${currentPage !== totalPages ? 'main-color' : 'secondary-color'} pagination-style p-0  `} >
                            <div className='d-flex align-items-center '>
                                <p className='m-0 pe-1'>Next</p>
                                <IoIosArrowForward />
                            </div>
                        </Button>
                    </div>
                </div> */}
            </div >
                : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
        </>
    )
}

export default Index