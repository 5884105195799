export default {
  LIMIT: 20,

  SCOPE: {
    ADMIN: "1",
    MANAGER: "2",
    STAFF: "3",
  },
  VERIFICATION_STATUS: {
    PENDING: 1,
    VERIFIED: 2,
    REWORK: 3,
  },
  // -------------------x--------
  _STATUS: {
    PICKUP: 1,
    DELIVER: 2,
    DELIVERED: 3,
    CANCELLED: 4,
  },
  // -------x-----------x--------
  BARCODE_STATUS: {
    PENDING: 1,
    APPROVED: 2,
  },
  PROCUREMENT_ITEM_STATUS: {
    PENDING: 1,
    TO_BE_DELIVERED: 2,
    DELIVERED: 3,
    CANCEL: 4,
  },

  SIZE_FOR: {
    TEMPLE: 1,
    BRIDGE: 2,
    GLASS: 3,
  },

  STORE_OWNERSHIP: [
    { label: "Specxweb Ownership", value: 1 },
    { label: "Store Ownership", value: 2 },
  ],

  BRAND_CATEGORY: [
    { label: "Recognized", value: 1 },
    { label: "Unrecognized", value: 2 },
  ],

  STORE_FURNITURE_TYPE: [
    { label: "Luxury AC Showroom", value: 1 },
    { label: "Premium AC Showroom", value: 2 },
    { label: "Non AC Good Furnised Store", value: 3 },
    { label: "Basic Furnised Store", value: 4 },
    { label: "Low Furnised Store", value: 5 },
  ],
  STATUS: {
    ACTIVE: "Active",
    DEACTIVE: "Deactive",
  },
  GENDER: [
    { label: "MALE", value: 1 },
    { label: "FEMALE", value: 2 },
    { label: "KIDS", value: 3 },
  ],
  PRODUCT_WEIGHT: [
    { label: "LIGHT", value: 1 },
    { label: "AVERAGE", value: 2 },
    { label: "ABOVE AVERAGE", value: 3 },
  ],
  // PRODUCT_AGE_GROUP: [
  //   { label: 'KIDS', value: 1 },
  //   { label: 'TEENAGER', value: 2 },
  //   { label: 'ADULT', value: 3 },
  //   { label: 'OLD', value: 4 },
  // ],
  PRODUCT_FEATURES: [
    { label: "MATT", value: 1 },
    { label: "GRADIENT", value: 2 },
    { label: "TRANSPARENT", value: 3 },
    { label: "BLUE CUT", value: 4 },
    { label: "MIRROR", value: 5 },
    { label: "PHOTOCROMATIC", value: 6 },
    { label: "DEMI HAVANA", value: 7 },
    { label: "FIX NOSEPAD", value: 8 },
  ],

  PRODUCT_RIM_TYPE: [
    { label: "FULL RIM", value: 1 },
    { label: "HALF RIM", value: 2 },
    { label: "RIM LESS", value: 3 },
    { label: "CLIP ON", value: 4 },
  ],
  PRODUCT_WARRANTY: [
    { label: "6 MONTHS", value: 1 },
    { label: "12 MONTHS", value: 2 },
    { label: "24 MONTHS", value: 3 },
  ],

  STORE_STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
    BLOCKED: 3,
  },

  POLARIZED: [
    { label: "YES", value: 1 },
    { label: "NO", value: 2 },
    { label: "NA", value: 3 },
  ],
  GST_DETAILS: {
    REGULAR: 1,
    COMPOSITION: 2,
    NA: 3,
  },
  DOC_TYPE: {
    gst_number: "GST",
    gst_document: "GST",
    pan_number: "PAN",
    pan_document: "PAN",
    gumasta_number: "GUMASTA",
    gumasta_document: "GUMASTA",
    nagar_nigam_number: "NAGAR_NIGAM",
    nagar_nigam_document: "NAGAR_NIGAM",
    incorporation_number: "INCORPORATION",
    incorporation_document: "INCORPORATION",
    agency_number: "AGENCY",
    agency_document: "AGENCY",
  },
  INVOICE_CATEGORY: [
    { label: "SPECXWEB INVOICE", value: 1 },
    { label: "WARRANTY", value: 2 },
    { label: "FREE GOODS", value: 3 },
    { label: "SAMPLE", value: 4 },
    { label: "EXISTING INVENTORY", value: 5 },
    { label: "NEW PURCHASE", value: 6 },
  ],
  INVENTORY_ITEM_CONDITION: [
    { label: "GOOD SALEABLE", value: 1 },
    { label: "MINOR DEFECT", value: 2 },
    { label: "MAJOR DEFECT", value: 3 },
    { label: "NOT SALEABLE", value: 4 },
  ],

  Account_type: [
    { label: "Assets", value: 1 },
    { label: "Liabilities", value: 2 },
    { label: "Equity", value: 3 },
    { label: "Revenue", value: 4 },
    { label: "Expenses", value: 5 },
    { label: "Store Assets", value: 6 },
    { label: "Store Liabilities ", value: 7 },
    { label: "Store Revenue", value: 8 },
    { label: "Store Expenses", value: 9 },
  ],

  ACCOUNT_HOLDER_TYPES: [
    { label: "STORE", value: 1 },
    // { label: 'SUPPLIER', value: 2 },
    // { label: 'CUSTOMER', value: 3 },
    { label: "OWN - SPECXWEB", value: 4 },
  ],

  TRANSACTION_TYPE: [
    { label: "Credit", value: 1 },
    { label: "Debit", value: 2 },
  ],

  ACCOUNTING_EVENTS: [
    { label: "INVOICE_ADDITION", value: 1 },
    { label: "INVENTORY_MOVEMENT", value: 2 },
    { label: "INVOICE_PREPARED ", value: 3 },
    { label: "ADVANCE_RECEIPT_PREPARED", value: 4 },
    { label: "ADVANCE_RECEIPT_CANCELLED", value: 5 },
    { label: "INVENTORY_RETURNED", value: 6 },
    { label: "MANUAL_ENTRY", value: 7 },
    { label: "PAYMENT_VOUCHER", value: 8 },
    { label: "PRICE_UPDATE", value: 9 },
    { label: "WARRANTY_CLAIMED", value: 10 },
    { label: "BRAND_COST_REVISED", value: 11 },
    { label: "LOST_BARCODE", value: 12 },
  ],

  warrantee_claim_for: [
    { label: "Frame Color Fade", value: 1 },
    { label: "Lens Color Fade", value: 2 },
    { label: "Break from joints", value: 3 },
    { label: "Manufacturing Fault", value: 4 },
  ],

  DailyReportFormat: [
    { value: 1, label: "PDF" },
    { value: 2, label: "EXCEL" },
  ],

  BooksOfAccount: [
    { value: true, label: "Specxweb" },
    { value: false, label: "Book Of Stores" },
  ],

  DailyReportFormatExcel: [{ value: 2, label: "EXCEL" }],

  MovementCancelReason: [
    { label: "Product Not found", value: 1 },
    { label: "Product Damaged", value: 2 },
    { label: "Product Not salable", value: 3 },
    { label: "Others", value: 4 },
  ],

  PermissionsGranting: [
    { label: "Sub Admin Permission", value: false },
    { label: "Staff Permission", value: true },
  ],

  STORE_BRAND_STATUS:[
    {label:"SHARED", value:1},
    {label:"NOT_SHARED", value:2},
    {label:"NO_ACCESS", value:3},
    {label:"BLOCK", value:4},
  ],
  
  LatitudeRegex: /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
  LongitudeRegex: /^-?(1[0-7]?\d(\.\d+)?|180(\.0+)?|\d{1,2}(\.\d+)?)$/,
  NumberValidationRegex: /^-?\d+(\.\d+)?$/,
};
